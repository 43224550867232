<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <FilterBonifica
      @viewDetailMatricole="viewDetailMatricole"
      @accorpaMatricole="accorpaMatricole"
      @setShowAccorpaBtn="setShowAccorpaBtn"
      :refresh="refresh"
      :showAccorpaBtn="showAccorpaBtn"
    />
    <TableBonifica
      :matricolaCorretta="matricolaCorretta"
      :matricolaErrata="matricolaErrata"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ref } from "vue";
//import { useRouter } from "vue-router";

import FilterBonifica from "@/components/components-fit/bonifica-anagrafica/FilterBonificaAnagrafica.vue";
import TableBonifica from "@/components/components-fit/bonifica-anagrafica/TableBonificaAnagrafica.vue";
import {
  VisualizzaDettaglioMatricola,
  BonificaAnagrafica,
} from "@/requests/bonificaRequests";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "bonifica-anagrafiche",
  components: {
    FilterBonifica,
    TableBonifica,
    Loading,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Bonifica anagrafica", []);
    });

    const matricolaCorretta = ref([]);
    const matricolaNonTrovata1 = ref(null);
    const matricolaErrata = ref([]);
    const matricolaNonTrovata2 = ref(null);
    const isLoading = ref(false);
    const refresh = ref(false);

    //const numeroMatricolaErrata = ref(null);
    //const numeroMatricolaCorretta = ref(null);

    const showAccorpaBtn = ref(false);
    const setShowAccorpaBtn = (value) => {
      showAccorpaBtn.value = value;
    };

    const viewDetailMatricole = async (MatricolaCorretta, MatricolaErrata) => {
      matricolaCorretta.value = [];
      matricolaErrata.value = [];
      matricolaNonTrovata1.value = null;
      matricolaNonTrovata2.value = null;
      /*  numeroMatricolaCorretta.value = MatricolaCorretta;
      numeroMatricolaErrata.value = MatricolaErrata; */
      if (!MatricolaCorretta || !MatricolaErrata) {
        alertFailed(
          "Attenzione! Per procedere è necessario compilare i campi matricola"
        );
        return;
      } else if (MatricolaCorretta == MatricolaErrata) {
        alertFailed(
          "Attenzione! Non è possibile accorpare la stessa matricola"
        );
      } else {
        isLoading.value = true;
        await VisualizzaDettaglioMatricola(MatricolaCorretta).then((res) => {
          if (res.status == 200 && res.data.results[0]) {
            matricolaCorretta.value = res.data.results[0];
          } else {
            matricolaNonTrovata1.value = MatricolaCorretta;
          }
        });
        await VisualizzaDettaglioMatricola(MatricolaErrata).then((res) => {
          console.log(res);
          if (res.status == 200 && res.data.results[0]) {
            matricolaErrata.value = res.data.results[0];
          } else {
            matricolaNonTrovata2.value = MatricolaErrata;
          }
        });
        if (matricolaNonTrovata1.value || matricolaNonTrovata2.value) {
          alertFailed(
            "Attenzione! Non è stato possibile caricare i dati relativi alla matricola numero " +
              (matricolaNonTrovata1.value ? matricolaNonTrovata1.value : "") +
              (matricolaNonTrovata1.value && matricolaNonTrovata2.value
                ? " e "
                : " ") +
              (matricolaNonTrovata2.value ? matricolaNonTrovata2.value : "")
          );
        } else {
          showAccorpaBtn.value = true;
        }
        isLoading.value = false;
      }
    };

    const accorpaMatricole = async (MatricolaCorretta, MatricolaErrata) => {
      isLoading.value = true;
      await BonificaAnagrafica(MatricolaCorretta, MatricolaErrata, false).then(
        (res) => {
          isLoading.value = false;
          if (res.status == 200) {
            Swal.fire({
              html: "Anagrafica bonificata correttamente!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Visualizza anagrafica",
              cancelButtonText: "Chiudi",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then((input) => {
              refresh.value = !refresh.value;
              if (input.isConfirmed) {
                window.open(
                  "/persone/dettaglio-persona/anagrafica-persona/" +
                    matricolaCorretta.value.id_persona,
                  "_blank"
                );
                /* router.push(
                  "/persone/dettaglio-persona/anagrafica-persona/" +
                    matricolaCorretta.value.id_persona
                ); */
              }
              matricolaCorretta.value = [];
              matricolaErrata.value = [];
            });
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        }
      );
    };

    return {
      viewDetailMatricole,
      isLoading,
      matricolaCorretta,
      matricolaErrata,
      accorpaMatricole,
      refresh,
      showAccorpaBtn,
      setShowAccorpaBtn,
      /*  numeroMatricolaCorretta,
      numeroMatricolaErrata, */
      matricolaNonTrovata1,
      matricolaNonTrovata2,
    };
  },
});
</script>
